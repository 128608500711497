.home_container {
    row-gap: 7rem;
}

.home_content {
    grid-template-columns: 120px repeat(2, 1fr);
    padding-top: 5.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home_social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home_social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home_social-icon:hover {
    color: var(--title-color-dark);
}

.home_title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
}

.home_subtitle {
    position: relative;
    font-size: var(--h2-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home_description {
    max-width: 400px;
    margin-bottom: var(--mb-3);
}

.home_subtitle::before {
    content: '';
    position: absolute;
    left: 0;
    top: 1rem;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
}

.home_social-icon:hover {
    color: var(--title-color-dark);
}

.home_img {
    background: url(../../assets/Link.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

/* Breakpoints */

/* For large devices */
@media screen and (max-width: 992px) {
    .home_content {
        grid-template-columns: 100px repeat(2, 1fr);
        column-gap: 1.25rem;
    }
    .home_subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home_subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home_description {
        max-width: initial;
        margin-bottom: var(--mb-2-25);
    }

    .home_img {
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 8px rgb(255, 255, 255 / 30%);
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .home_content {
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
        text-align: center;

    }

    .home_img {
        order: initial;
        justify-self: initial;
        box-shadow: inset 0 0 0 6px rgb(255, 255, 255 /30%);
        width: 200px;
        height: 200px;
        justify-content: center;
    }

    .home_subtitle::before {
        display: none;
    }

    .home_description {
        margin-bottom: var(--mb-2);
        padding: 1rem;
    }

    .home_subtitle {
    padding-left: 3.75rem;
    margin-bottom: var(--mb-1);
    font-size: var(--h3-font-size);
    }

    .home_data {
        grid-column: 1/3;
        padding: 1rem;

    }
}

/* For small devices */
@media screen and (max-width: 375px) {
    .home_content {
        row-gap: 1.5rem;
        padding-top: 2rem;
    }

    .home_img {
        order: initial;
        justify-self: initial;
        box-shadow: inset 0 0 0 6px rgb(255, 255, 255 /30%);
        width: 180px;
        height: 180px;
    }

    .home_data {
        grid-column: 1/3;
        padding: 0.75rem;
    }

    .home_description {
        margin-bottom: 0;
        padding: 1rem;
    }

    .home_subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
        font-size: var(--h3-font-size);

    }

    .button {
        padding: 1.5rem;
        margin-left: 9rem;
    
        }
}
