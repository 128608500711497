.cursor-follower{
    position: fixed;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 255, 200, 0.731);
  border: 2px solid rgb(205, 254, 204);
  border-radius: 50%;
  pointer-events: none;
  z-index: 1000;
  transform: translate(-50%, -50%);
  mix-blend-mode: difference;
}

/* .particle {
    position: fixed;
    width: 8px;
    height: 8px;
    background: #FFD700;
    border-radius: 50%;
    pointer-events: none;
    z-index: 998;
    transform: translate(40%, 40%);
} */